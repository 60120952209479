//----------------------------------
// Blocks
//----------------------------------

$_blocks-gutter: 5%; // Needs to be a percentage
$_blocks-vertical-gutter: 20px;
$_blocks-sizes: 1, 2, 3, 4;

.blocks {
  margin-left: -$_blocks-gutter;
  font-size: 0; // Removes whitespace between blocks

  > * {
    width: 100% - $_blocks-gutter; // To fill space if there are no size rules applied
    margin-left: $_blocks-gutter;
    margin-bottom: $_blocks-vertical-gutter;
    display: inline-block;
    font-size: $font-size-default; // Reset font size
    vertical-align: top;
  }
}

.blocks-compact {
  margin-bottom: -10px;

  > * {
    margin-bottom: 10px;
  }
}

@include blocks-layout($_blocks-gutter, $_blocks-sizes);

@include blocks-layout($_blocks-gutter, $_blocks-sizes, 28em, small);

@include blocks-layout($_blocks-gutter, $_blocks-sizes, $media-md, tablet);

@include blocks-layout($_blocks-gutter, $_blocks-sizes, $media-lg, desktop);

//-----------------------------------
// Global Variables
//-----------------------------------

// Media query breakpoints
$media-md: 40em; // Tablet, 640px
$media-lg: 52.5625em; // Desktop, 841px
$media-xl: 70em; // Large Desktop, 1120px
// Footer break points
$media-tablet: 48em; //768px
$media-small-tablet: 32em; //512px
// Colors
$color-transparent: rgba(255, 255, 255, 0);
$color-white: #ffffff;
$color-black: #000000;
$color-green: #2eba80;
$color-election-blue: #4298b5;
$color-election-dark: #072b31;
$color-election-red: #eb5c40;
$color-blue: #4298b5;
$color-blue-tint: #dae9f1;
$color-blue-light: #94c4d9;
$color-lightblue: $color-blue-light;
$color-blue-dark: #00334e;
$color-darkblue: $color-blue-dark;
$color-blue-faded: #3f6275;
$color-mpr-primary: #00334e;
$color-link: $color-blue-dark;
$color-link-hover: $color-blue-faded;
$color-charcoal: #2a2a2a;
$color-lightgray: #d3d2d0;

// Temp podcast theme color
$color-theme-text: $color-blue-dark;
$color-theme: $color-blue;
$color-theme-highlight: $color-blue-faded;

$color-bg: #ffffff;
$color-light: #eeeeee;
$color-mid: #aaaaaa;
$color-text-default: #333333;
$color-dark: #555555;
$color-heading: $color-mpr-primary;
$color-heading-dark: $color-mpr-primary;
$color-button-bg: #e4ebed;
$color-border-after: rgba(238, 238, 238, 0);
$color-border-before: rgba(0, 77, 133, 0);

$color-win: $color-green;
$color-dem: #4298b5;
$color-gop: #eb5c40;
$color-ind: #f9a909;
$color-grn: $color-green;
$color-lib: #fc6d80;
$color-lmn: #177840;
$color-grp: #0a3c20;
$color-cst: #978054;
$color-swp: #6b0000;
$color-adp: #12515d;
$color-yes: $color-green;
$color-no: #eb5c40;
$color-oth: #887777;
$color-wi: #999999;
$color-no-results: #d5d5d5;
$color-footer-hdg: #191a1a;
$color-white-85: rgba(255, 255, 255, .85);
$color-white-60: rgba(255, 255, 255, .6);
$color-black-30: rgba(0, 0, 0, .3);

// Font Sizes
$font-family-default: 'Noto Sans', system-ui, sans-serif;
$font-family-heading: 'Noto Sans', system-ui, sans-serif;
$font-family-body: 'Noto Sans', system-ui, sans-serif;
$font-sans:  'Noto Sans', system-ui, sans-serif;
$font-footer: $font-sans;
// Font Sizes
$font-size-default: 15px;
$font-size-default-lg: 20px;

// Z-index
$z-nav-dropdown: 1600;
$z-header-logo: 1540;
$z-nav-button: 1521;
$z-nav: 1520;
$z-map-overlay: 1201;
$z-map-dropdown: 1200;
$z-graph-overlay: 510;

$_btn-transition: .2s;
$_btn-transition-fast: .05s;

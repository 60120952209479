//----------------------------------
// State SVGs
//----------------------------------

.stateSvg {
  width: 32px;
  height: 32px;
  margin: 4px 8px;
  display: flex;
  align-items: center;
  position: relative;
  top: 0;

  @include media(64em) {
    margin: 0 8px;
    top: 7px;
  }

  svg {
    display: block;
    height: 32px;
    width: 32px;
    fill: $color-dark;

    @include media(64em) {
      height: 38px;
      width: 38px;
    }
  }
}

[data-party='GOP'] .stateSvg {
  svg {
    fill: $color-gop;
  }
}

[data-party='Dem'] .stateSvg {
  svg {
    fill: $color-dem;
  }
}

.stateSvg-national {
  top: 0;
}

//--------------------------------------------------
// HTML Element defaults
//--------------------------------------------------

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: $font-family-default;
  font-size: $font-size-default;
  -webkit-font-smoothing: antialiased;
  color: $color-text-default;
  background: $color-bg;
}

img {
  display: block;
  width: 100%;
}

ul,
ol,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

select {
  background-color: $color-mpr-primary;
  color: $color-white;
  border: 1px $color-election-blue solid;
  padding: 5px 10px;
  border-radius: 3px;
  margin-bottom: 20px;
}

.controls {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}

.controls button {
  background-color: $color-mpr-primary;
  color: $color-white;
  border: 2px $color-election-blue solid;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.25rem;
  width: 2.25rem;
}

th {
  font-weight: 600;
}

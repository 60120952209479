//----------------------------------
// Graph
//----------------------------------

$_graph-height: 20px;

.graph {
  display: block;
  width: 100%;
  height: $_graph-height;
  margin-bottom: 5px;
  position: relative;
  font-size: 0;
  white-space: nowrap;
  background: $color-light;
}

.graph-bop {
  display: block;
  height: 20px;

  .graph_segment {
    display: block;
    float: right;
    border-left: 0;

    &:first-child {
      float: left;
    }
  }
}

.graph-ev {
  height: 28px;
  margin-bottom: 6px;

  @include media-max(22.5em) {
    margin-top: 20px;
  }
}

.graph_segment {
  display: inline-block;
  position: relative;
  height: 100%;
  box-shadow: 0 0 0 1px $color-white;
  background-color: transparent;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-wi;
    transition: opacity .25s;
  }

  &::after {
    border-top-color: $color-wi;
  }

  &:first-child::after,
  &:nth-child(2)::after,
  &.active::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -4px;
    border-top: 5px solid $color-wi;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 0;
  }

  .race-full &::before {
    opacity: .5;
  }

  .race-full.has-winner &::before {
    opacity: 1;
  }

  .has-winner &:not(:first-child)::before {
    opacity: 0;
  }

  &.is-hover:not(.is-selected)::before {
    opacity: 0;
  }

  &.is-hover.is-selected::before {
    opacity: 1;
  }

  &.left {
    float: left;
  }

  &.right {
    float: right;
  }

  &.leading::before {
    opacity: .3;
  }

  &[style='width: 0%;']::after {
    content: normal;
  }

  &.noPointer::after {
    content: normal;
  }

  &.active::after {
    right: 0;
  }

  &:first-child::after {
    left: 8px;
    right: auto;
  }

  &:nth-child(2)::after,
  &.right::after {
    right: 8px;
  }

  &.dem {
    &::before {
      background-color: $color-dem;
    }

    &::after {
      border-top-color: $color-dem;
    }
  }

  &.gop {
    &::before {
      background-color: $color-gop;
    }

    &::after {
      border-top-color: $color-gop;
    }
  }

  &.ind {
    &::before {
      background-color: $color-ind;
    }

    &::after {
      border-top-color: $color-ind;
    }
  }

  &.grn {
    &::before {
      background-color: $color-grn;
    }

    &::after {
      border-top-color: $color-grn;
    }
  }

  &.lib {
    &::before {
      background-color: $color-lib;
    }

    &::after {
      border-top-color: $color-lib;
    }
  }

  &.lmn {
    &::before {
      background-color: $color-lmn;
    }

    &::after {
      border-top-color: $color-lmn;
    }
  }

  &.grp {
    &::before {
      background-color: $color-grp;
    }

    &::after {
      border-top-color: $color-grp;
    }
  }

  &.adp {
    &::before {
      background-color: $color-adp;
    }

    &::after {
      border-top-color: $color-adp;
    }
  }

  &.cst {
    &::before {
      background-color: $color-cst;
    }

    &::after {
      border-top-color: $color-cst;
    }
  }

  &.swp {
    &::before {
      background-color: $color-swp;
    }

    &::after {
      border-top-color: $color-swp;
    }
  }

  &.yes {
    &::before {
      background-color: $color-yes;
    }

    &::after {
      border-top-color: $color-yes;
    }
  }

  &.no {
    &::before {
      background-color: $color-no;
    }

    &::after {
      border-top-color: $color-no;
    }
  }

  &.oth {
    &::before {
      background-color: $color-oth;
    }

    &::after {
      border-top-color: $color-oth;
    }
  }

  &.wi {
    &::before {
      background-color: $color-wi;
    }

    &::after {
      border-top-color: $color-wi;
    }
  }
}

.electionDropdown {
  font-family: $font-family-heading;
  background-color: $color-light;
  color: $color-election-dark;
  font-size: 1rem;
  padding: 0;
  text-align: center;

  .Dropdown-placeholder {
    font-weight: 600;
    padding: 5px;
    border-bottom: 1px solid $color-election-blue;
    border-top: 1px solid $color-election-blue;
    display: flex;
    justify-content: center;
  }

  .Dropdown-menu {
    background-color: $color-white;

    .Dropdown-option {
      font-weight: normal;
      padding: 2.5px 0;
      border-bottom: 1px solid $color-election-blue;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .Dropdown-arrow-wrapper {
    width: 11px;
    margin-left: 10px;
    display: none;
  }
}

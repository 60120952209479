//----------------------------------
// Live badge
//----------------------------------

@keyframes fade {
  0% { opacity: 1; }
  5% { opacity: 0; }
  10% { opacity: 1; }
  100% { opacity: 1; }
}

.live_text {
  display: inline-block;
  font-size: 14px;
  font-family: $font-family-body;
  color: $color-election-dark;
  font-style: italic;
}

.live_icon {
  display: inline-block;
  margin-left: 8px;
  transform:  scale(1);
  animation: pulse 2s infinite;

  svg {
    vertical-align: -5px;
    height: 20px;
    width: 20px;
  }

  .icon-live-dot {
    animation: fade 10s infinite linear;
    animation-delay: 0s;
  }

  .icon-live-wave-1 {
    animation: fade 10s infinite linear;
    animation-delay: .2s;
  }

  .icon-live-wave-2 {
    animation: fade 10s infinite linear;
    animation-delay: .3s;
  }
}

@keyframes pulse {
  0% {
    transform: scale(.95);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(.95);
  }
}

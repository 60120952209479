//----------------------------------
// Map interface
//----------------------------------

.rsm-svg {
  position: relative;
}

.rsm-geography:focus {
  outline: none;
}

.legend {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 5px;
}

.legend_node {
  width: 170px;

  span {
    font-size: .9rem;
    font-weight: normal;
    color: $color-black;
  }

  svg {
    height: 15px;
    width: 15px;
    margin-right: 5px;
    border: 1px solid $color-black;
  }
}

.tooltip {
  font-family: $font-family-default;
}

.tooltip_title {
  text-decoration: underline;
  font-family: $font-family-heading;
  text-align: center;
  padding: 5px 0;
}

.tooltip_label {
  font-family: $font-family-heading;
  padding-right: 10px;
  text-align: right;
}

// .map_overlay {
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   z-index: $z-map-overlay;
// }

// .map_overlay_link {
//   padding: 10px 20px;
//   border-right: 3px solid $color-election-dark;
//   position: absolute;
//   top: 25px;
//   right: 20px;
//   color: $color-election-dark;
//   font-family: $font-family-heading;
//   font-size: 20px;
//   line-height: 1;
//   text-align: right;
//   background: rgba($color-light, .8);

//   &:hover {
//     color: $color-election-blue;
//     border-right-color: $color-election-blue;
//   }
// }

// .map_tooltip {
//   visibility: hidden;
//   position: absolute;
//   right: 0;
//   z-index: 1200;
//   text-align: center;
//   font-family: 'ITC Franklin Gothic Dm Cd', Helvetica, sans-serif;
//   font-size: 13px;
//   color: $color-election-dark;
//   pointer-events: none;
//   border: 1px solid $color-election-dark;
//   background: $color-light;
//   width: 75px;

//   &::after,
//   &::before {
//     right: 100%;
//     top: 50%;
//     border: solid transparent;
//     content: ' ';
//     height: 0;
//     width: 0;
//     position: absolute;
//     pointer-events: none;
//   }

//   &::after {
//     border-color: $color-border-after;
//     border-right-color: $color-light;
//     border-width: 4px;
//     margin-top: -4px;
//   }

//   &::before {
//     border-color: $color-border-before;
//     border-right-color: $color-election-dark;
//     border-width: 5px;
//     margin-top: -5px;
//   }

//   &_inner {
//     display: inline-block;
//     padding: 5px 4px;
//   }
// }

// .map_close {
//   position: absolute;
//   top: 0;
//   right: 0;
//   margin: 34px 10px 0 0;
//   z-index: $z-map-dropdown;
// }

// .map_dropdown {
//   width: 50%;
//   max-width: 200px;
//   position: absolute;
//   top: 0;
//   right: 0;
//   z-index: $z-map-dropdown;

//   &::after {
//     content: '';
//     display: block;
//     width: 0;
//     height: 0;
//     margin-top: -3px;
//     border-top: 6px solid $color-election-dark;
//     border-left: 4px solid transparent;
//     border-right: 4px solid transparent;
//     border-bottom: 0;
//     position: absolute;
//     top: 50%;
//     right: 10px;
//     pointer-events: none;
//   }
// }

// .map_dropdown_select {
//   width: 100%;
//   padding: 3px 20px 3px 10px;
//   border: 0;
//   border-radius: 0;
//   appearance: none;
//   font-size: 16px;
//   font-family: $font-family-heading;
//   color: $color-election-dark;
//   background: $color-light;
//   cursor: pointer;
// }

// .map_legend {
//   margin: 5px 0;
// }

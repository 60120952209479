//----------------------------------
// Split
//----------------------------------

.split {
  display: table;
  width: 100%;

  > * {
    display: table-cell;
    vertical-align: middle;
  }
}

.split_right {
  text-align: right;
}

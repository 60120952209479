//----------------------------------
// Full Results
//----------------------------------

$_results-padding: 5px;

.results_item {
  display: table;
  width: calc(100% + #{$_results-padding * 2});
  padding: ($_results-padding); // padding instead of margin so we don't get a gap when hovering
  margin-left: -($_results-padding); // negative margin to exend past the column bounds when hovering
  margin-right: -($_results-padding);
  transition: background-color .25s;

  &.is-selected {
    background: $color-light;
  }

  .results-compact & {
    padding-top: 0;
    padding-bottom: 0;

    &.is-selected {
      background: transparent;
    }
  }
}

.results-compact > .results_item {
  margin-bottom: 0;
}

.results_item_tag {
  display: table-cell;
  width: 43px;
  vertical-align: top;
}

.results_tag {
  display: block;
  font-family: $font-family-heading;
  font-weight: 600;
  font-size: 10px;
  line-height: 1;
  text-transform: uppercase;
  color: $color-white;
  background-color: $color-wi;
  transition: transform .4s;

  .is-selected & {
    transform: translateX(5px);
  }

  .results-compact .is-selected & {
    transform: none;
  }

  &.dem { background-color: $color-dem; }
  &.gop { background-color: $color-gop; }
  &.ind { background-color: $color-ind; }
  &.grn { background-color: $color-grn; }
  &.lib { background-color: $color-lib; }
  &.lmn { background-color: $color-lmn; }
  &.grp { background-color: $color-grp; }
  &.cst { background-color: $color-cst; }
  &.adp { background-color: $color-adp; }
  &.swp { background-color: $color-swp; }
  &.yes { background-color: $color-yes; }
  &.no { background-color: $color-no; }
  &.oth { background-color: $color-oth; }
  &.wi { background-color: $color-wi; }
}

.results_tag_party {
  display: block;
  padding: 3px 6px;
  text-align: center;
}

.results_tag_img {
  display: block;
  height: 40px;
  width: 40px;
  background: $color-white-85;
  margin-left: 3px;
  transition: background-color .4s;

  .is-selected & {
    background-color: $color-white-60;
  }

  + .results_tag_party {
    display: none;
    padding: 1px;
  }
}

.results_stats_left {
  display: table-cell;
  padding-left: 6px;
  vertical-align: top;
  transition: transform .4s;

  .is-selected & {
    transform: translateX(5px);

    .results-compact & {
      transform: none;
    }
  }
}

.results_stats_right {
  display: table-cell;
  text-align: right;
  vertical-align: top;
}

.results_pct {
  display: inline-block;
  padding: 0;
  font-family: $font-family-heading;
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
}

.results_pct_win {
  min-width: 50px;
  margin-left: 20px;
  padding-left: 2px;
  padding-right: 2px;
  background-color: $color-green;
  color: $color-white;
  position: relative;

  &::after {
    position: absolute;
    top: 2px;
    left: -16px;
    font-family: $font-family-heading;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    color: $color-green;
  }

  &.ap::after {
    content: 'AP';
    left: -20px;
  }

  &.mn_oss::after {
    content: 'Final';
    left: -40px;
  }

  svg {
    display: inline-block;
    height: 11px;
    width: 14.5px;
    vertical-align: -1px;
    fill: $color-white;
  }
}

.results_text {
  font-size: 15px;
}

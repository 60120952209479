//-----------------------------------
// Styles for MPR News header
// Imported from mprnews css
//-----------------------------------

// Variables
$mpr-brand-primary: #00334e;  //mpr news blue
$mpr-brand-secondary: #3d8fa5;  //mpr news light blue
$mpr-light-blue: #e4ebed;
$mpr-context-text: #999999;

$mpr-brand-warning: #9a272a;

$mpr-current: #b70005;
$mpr-classical: #6ca42d;
//html, body{ font-family: 'DejaVuSans', sans-serif; }
$mpr-hitemp:        #ae6a0a;
$mpr-lotemp:        #4595ab;

$mpr-border: 2px solid #cccccc;

// Scaffolding
$mpr-body-background:         #ffffff;
$mpr-text-color:              #1a1a1a;

// Links
$mpr-link-color:              $mpr-brand-primary;
$mpr-link-color-hover:        lighten($mpr-link-color, 15%);
$mpr-link-color-visited:      desaturate(lighten($mpr-brand-primary, 20%),70%);

// Typography
$mpr-font-family-sans-serif:  'Noto Sans', system-ui, sans-serif;
$mpr-font-family-default:     $mpr-font-family-sans-serif;
$mpr-font-family-serif:       $font-family-body;
$mpr-font-family-monospace:   'Noto Sans Mono', ui-monospace, monospace;
$mpr-font-family-base:        $font-family-body;

// Mixins
@mixin iconfont(){
  font-family: 'mprnews';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


#news-logo{
  width: 200px;
  margin: 0 auto;
  padding-top: 8px;
  img{
    display: inline;
    width: 134px;
    margin-top: 4px;
  }
}

#mprnews-nav{
  font-size: 14px;
  font-family: $mpr-font-family-default;
  width: 100%;
  background-color: transparent;
  //padding: 10px 0;
  height: 42px;
  border-bottom: $mpr-border;
  text-align: center;
  z-index: 100;
    //font-family: $mpr-font-family-sans-serif;
  a{
    text-decoration: none;
    font-weight: 600;
    &:visited{ color: $mpr-link-color; }
  }
  ul, li{
    padding: 0;
    //margin: 0;
  }
  .contribution-link{
    color: $mpr-brand-warning;
    border: 2px solid $mpr-brand-warning;
    text-transform: uppercase;
    padding: 0 8px;
    font-weight: 600;
  }

}


#nav-top{
  a.contribution-link{
    display: inline-block;
    margin: 10px;
  }
  ul{
    display: none;
    width: 100%;
    padding: 10px;
    &#nav-primary{
      @include clearfix();
    }
  }
  li{
    text-align: left;
    line-height: 2em;
    &.header{
      a, span{
      color: $mpr-context-text;
      font-weight: 600;
      }
      span{
        padding-left: 10px;
      }
    }
    span.header{
      color: $mpr-context-text;
      margin: 5px 10px 0;
    }
    span.header, #more-sections-header{
      border-bottom: $mpr-border;
      border-bottom-color: #e5e5e5;
      display: block;
      line-height: 1.5;
      font-size: 1em;
      margin: 5px 10px 0;
      padding-left: 0;
    }
    li.header{
      padding: 10px 10px 0 0;
    }
    &.nav-secondary{
      display: none;
    }
    &.current a{
      color: $mpr-current;
    }
    &.classical a{
      color: $mpr-classical;
    }
    ul, ul li{
      padding: 0;
      //margin: 0;
    }
    a:hover{
      background-color: $mpr-light-blue;
    }
  }
  a{
    display: block;   //make links the whole width
    font-size: 1.14em; //16px
    //font-weight: normal;
    padding: 0 10px;
  }
  .nav-dropdown{
    //margin-top: 10px;
    font-weight: 600;
  }
  .nav-dropdown-header{ display: none;}
  #nav-news-sections{

    width: 54%;
    float: left;
    border-right: $mpr-border;
    &.nav-dropdown{ margin-top: 0;}


    a{
      font-weight: normal;
    }
  }
  span{
    font-size: 1.14em; //16px
  }
}


#nav-top {
  &.nav-open {
    position: relative;
    #nav-list{
      display: block;
      width: 100%;
      margin-top: 42px;
      position: absolute;
      z-index: 30;
      padding-bottom: 10px;
      ul{
        display: block;
        &.sub{
          display: none;
        }
      }
    }
    #nav-primary{
      background-color: #fff;
      padding: 0 0 1em 0;
      margin: 0;
      border-bottom: $mpr-border;
      box-shadow: 0 1px 2px rgba(0,0,0,0.3);

    }
    #nav-top-opener{
      height: 42px;
      margin-top: 0px;
      background-color: white;
      &:before{
        line-height: 2.5em;
        content: "\e005";
      }
    }
  }
}


ul.nav-sub li{
  width: 100%;
  float: left;
  &.mpr, &.header, &.has-sub{ width: 100%;}
  &.has-sub{
    margin-top: 10px;
    &.first{
      margin-top: 0;
    }
  }
}
ul.nav-sub{
  @include clearfix();
}
#nav-services{
  .header{ font-weight: 600;}
  li.nav-sub{
    margin-top: 10px;
  }
  li.mpr{
    display: none;
  }
}
#nav-more{
  margin-top: 10px;
}
#more-sections-header{
  display: block; // make more header show up on mobile
  color: $mpr-context-text;
}

#more-sub-primary-services,
#more-sub-primary-services-header,
#more-sub-mpr-header{ display: none;} //hide on small and medium
#more-streams a{
  font-weight: normal;
  color: lighten($mpr-brand-primary, 15%);
}
#nav-services, #nav-more{
  float: right;
  width: 46%;
}


#nav-top-opener{
  display: none !important;
  float: left;
  width: 50px;
  height: 32px;  //32
  margin-top: 5px;
  margin-left: 0;
  border-right: $mpr-border;
  &:before{
    font-family: 'mprnews';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    font-size: 1.14em;
    line-height: 1.9;
    -webkit-font-smoothing: antialiased;
    content: "\e004";
  }
}
// .no-svg #nav-top-opener{
//   background-image: url('/img/hamburger.png');
// }

// hide contribution link in mobile
#nav-top-contribution{ display: none;}
#nav-mpr-give{
  a{ display: inline-block;}
  .mpr{float: left; margin: 13px 0; font-size: 1em;}
  .contribution-link { float: right;}
  //.clearfix();
  div{ @include clearfix(); }
}

#nav-search{
  display: none;
}
#nav-search, #base-search{
  label{  //need to check on accessibility for this
    height: 0;
    width: 0;
    overflow: hidden;
    display: block;
  }
}
#base-search {
  @include clearfix();
  width: 100%;
  padding: 0 10px;
  margin: 0 0 1em;
  .search-submit{
    @include iconfont();
    display: inline-block;
    margin: 0;
    border: 2px solid #cccccc;
    padding: 10px 20px 11px;
    position: relative;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    transition: background-color .15s ease-in-out;

    background: #ffffff;
    font-size: 1em;
    color: $mpr-brand-primary;
    background-color: $mpr-light-blue;
    width: 20%;
    float: left;
    -webkit-appearance: none;
    border-radius: 0;
    border-left: 0;
  }
}
input[type="search"]{
  -webkit-appearance: none;
    -webkit-border-radius: 0px;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
}

.searchbox{
  border: $mpr-border;
  background: #fff;
  width: 75%;
  height: 2.5em;
  margin: 0;
  z-index: 10;
  padding: 0 5px;
  color: $mpr-text-color;
  opacity: 1;
  float: left;
}
//set placeholder text using vendor prefixes
.searchbox::-webkit-input-placeholder {    color: $mpr-context-text; }
.searchbox:-moz-placeholder {           color: $mpr-context-text; }
.searchbox::-moz-placeholder {          color: $mpr-context-text; }
.searchbox:-ms-input-placeholder {        color: $mpr-context-text; }


//empty div that is used on large screen to simulate full width, we put a border on it
#nav-faux-wrapper{
  display: none;
}

@include media($media-md) {

  #mprnews-nav{
    font-size: 18px;
  	height: 52px;
  	//background-color: pink;
  	.contribution-link{
  		font-size: 0.778em; //14px
  		padding: 2px 6px;
  	}
  }

  #news-logo{
  	margin: 0px 0 0 0px;
  	padding-top: 14px;
  	img{
  		width: auto;
  		margin-top: 0;
  	}
  }



  #nav-top-contribution{
  	display: block;
  	position: absolute;
  	top: 12px;
  	right: 12px;
  }
  #nav-top{  //	&:hover, &.nav-open

  	a,span{
  		font-size: 1em;
  	}
  	a.contribution-link{
  		//line-height: 1.5;
  		display: none;
  	}
  	ul#nav-primary{
  		min-width: 450px;
  		width: 80%;
  	}

  }

  #nav-top{
  	&.nav-open {
  		#nav-top-opener{
  		background-position: 13px 16px;
  		height: 52px;
  		}
  		#nav-list{
  			margin-top: 52px;
  		}
  		#nav-primary{
  			border-right: $mpr-border;
  		}
  	}
  }

  #nav-top-opener{
  	width: 62px;
  	height: 42px;
  	background-position: 13px 11px;
  }


  /*
   * Very helpful:
   * http://tympanus.net/codrops/2013/06/26/expanding-search-bar-deconstructed/
   */
  #nav-search{
  	display: block;
  	position: absolute;
  	right: 0;
  	top: 0;
  	width: 0%;
  	min-width: 50px;
  	//margin-top: 10px;
  	overflow:hidden;
  	float: right;
  	-webkit-backface-visibility: hidden;

  	// Transitions are super buggy, bummer!
  	//.transition(~"width 200ms");
  	border-left: $mpr-border;
  	height: 32px;
  	margin-top: 5px;


  	.search-icon,
  	.search-submit{
  		width: 50px;
  	    height: 42px;
  	    display: block;
  	    position: absolute;
  	    right: 0;
  	    top: 0;
  	    padding: 0;
  	    margin: 0;
  	    line-height: 48px;
  	    text-align: center;
  	    cursor: pointer;
  	}
  	//intially hide the submit button
  	.search-submit{
  		background: #fff; /* IE needs this */
  	    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; /* IE 8 */
  	    filter: alpha(opacity=0); /* IE 5-7 */
  	    opacity: 0;
  	    color: transparent;
  	    border: none;
  	    outline: none;
  	    z-index: -1;
  	}
  	.search-icon{
  		background: #fff;
  	    z-index: 90;
  	    -webkit-font-smoothing: antialiased;
  	    margin-top: -5px;
  	    &:before{
  	    	font-size: 18px;
  			content: "\e000";
  			font-family: 'mprnews';
  			speak: none;
  			font-style: normal;
  			font-weight: normal;
  			font-variant: normal;
  			text-transform: none;
  			line-height: 1;
  			-webkit-font-smoothing: antialiased;
  			width: 40px;
  		}
  	}

  	.searchbox{
  		border: none;
  		outline: none;
  		width: 100%;
  		height: 40px;
  		margin: 0;
  		// z-index: 10;
  		// padding: 0;
  		// color: @text-color;
  		opacity: 0;
  		transition: opacity 200ms;
  		float: none;
  	}

  }

  #nav-search{
  	&.search-open{
  		width: 100%;
  		height: auto;
  		margin-top: 0;
  		border-left: 0;
  		padding-left: 48px;

  		.search-icon{
  			background-color: $mpr-light-blue;
  			margin-top: 0;
  		}
  		.searchbox{
  			border-left: $mpr-border;
  			padding: 0;
  			padding-left: 5px;
  			opacity: 1;
  		}
  	}
  }

  .lt-ie9 #nav-search{
  	&.search-open,
  	&:hover {
  		.searchbox{
  			height: 38px;
  		}
  	}
  }

  #nav-search{
  	height: 40px;
  	.searchbox{
  		height: 50px;
  	}
  	.search-icon{
  		margin-top: -2px;
  		&:before{
  			position: absolute;
  			top: 13px;
  			left: 5px;
  		}
  	}
  	.search-icon,
  	.search-submit{
  		height: 52px;
  	}
  }

  #nav-search.search-open{
  	padding-left: 60px;
  	.search-icon:before{
  		position: absolute;
  		top: 16px;
  		left: 5px;
  	}
  }

  #base-search{ display: none;}

}

@include media($media-lg) {

  #mprnews-nav{
  	position: relative;
  	margin: 0 auto;
  	border-bottom: none;
  }

  #news-logo{
  	position: absolute;
  	left: 0;
  	top: 5px;
  	margin: 0;
  	border-right: 3px solid #eeeeee;
  	img{
  		margin-top: -4px;
  		//padding-bottom: 3px;
  	}
  }


  #nav-top-contribution{

  }
  #nav-list{
  	width: auto;
  	background: transparent;
  	margin-top: 0 !important;
  }



  #nav-top {
  	li.nav-dropdown{
  		&.nav-open {  				//&:hover  // this is where safari has trouble   &.nav-open,
  		 	display: inline !important;
  		 	position: static;
  			div.nav-sub-wrapper{
  				position: absolute;
  				display: block;
  				width: 100%;
  				margin-left: 0 !important;
  				left: 0;
  				top: 54px;
  				background-color: #fff;
  				border-bottom: $mpr-border;
  				padding: 10px 0 20px 15px;
  				z-index: 1;
  				box-shadow: 0 1px 2px rgba(0,0,0,0.3);
  				a:hover{
  					text-decoration: underline;
  				}
  			}
  			a.nav-dropdown-header{
  				background-color: #fff;
  				border-left: $mpr-border;
  				border-right: $mpr-border;
  				padding: 9px 10px 9px 12px;
  				//color: @link-color-hover;
  				display: inline-block;
  			}
  			ul{
  				display: block !important;
  				font-size: 0.95em;
  				&.nav-sub{
  					float: left;
  					ul li{
  						width: 100%;
  					}
  					#more-sub-primary-services{
  						width: 44%;
  					}
  					#more-sub-secondary-services{
  						width: 56%;
  						a{ font-weight: normal; }
  					}
  					&.services {
  						width: 39%;
  					}
  					&.more{
  						width: 61%;
  						padding-left: 20px;
  						border-left: $mpr-border;
  						#nav-about{
  							width: 66%;
  							li{ width: 50%; float: left;}
  						}
  					}
  					li{
  						display: block;
  						width: 33.33%;
  						float: left;
  					}
  					li.has-sub{ margin-top: 0;}
  					li.mpr, li.header{ width: 100%; padding-top: 0;}
  				}
  				&.sub{
  					li{
  						width: 100%;
  						display: block;
  						a{ font-weight: normal; }
  					}
  				}
  			}
  			&#nav-news-sections{
  				li{ width: 23%;
  					li{ width: 100%;}
  				}
  				li.first{
  					width: 52%;
  					li{
  						width: 33%;
  					}
  				}
  				&.has-special-coverage{
  					li.first{
  						width: 31%;
  						li{
  							width: 50%;
  						}
  					}
  				}
  			}
  			li{
  				display: inline-block;
  			}
  			ul.sub{
  				display: block;
  			}
  			&#nav-members{
  				a.header{ font-weight: 600;}
  				li {
  					width: 40%;
  				}
  				.has-sub{
  					width: 60%;
  					float: right;
  					li{
  						width: 50%;
  						float: left;
  					}
  				}
  			}
  		}
  		li{
  		 	line-height: 1.8;
  		}
  	}
  	&.nav-open{ //&:hover
  		//margin-top: 0;
  		#nav-list{
  			ul#nav-primary{
  				box-shadow: none;
  				border-right: 0;
  				border-bottom: 0;
  				margin-left: 208px;
  			}
  			z-index: auto;
  			margin: 0;
  			position: static;
  			padding-bottom: 0;
  		}
  		#nav-top-opener{
  			display: none;
  		}
  		position: static;
  	}
  }


  #sub-secondary-members{ display: none !important;}

  #nav-top{
  	ul#nav-primary{
  		min-width: 0;
  		display: block;
  		position: static;
  		float: left;
  		margin-left: 208px;
  		width: 360px;
  		height: 50px;
  		padding: 0;
  		text-align: left;
  		margin-top: 0;
  	}
  	a:hover{
  		background-color: transparent !important;
  	}
    .nav-open a:hover {
      background-color: $color-white !important;
    }
  	#nav-news-sections, #nav-more{
  		width: auto;
  		float: none;
  		border: 0;
  	}
  	li #more-sections-header{
  		border-bottom: 0;
  		display: inline-block;
  		line-height: 2em;
  		margin-top: 0;
  		padding-left: 5px;
  		margin-left: 0;
  	}
  	li{
  		display: none;
  		&.nav-dropdown{
  			margin-top: 0;
  			float: none;
  			width: auto;
  			border: none;
  			display: inline-block;
  			li{
  			 	line-height: 1.8;
  			}
  		}
  		a.nav-dropdown-header{
  			display: inline-block;
  			padding: 9px 10px 6px 12px;
  			font-weight: normal;
  			border-left: 2px solid #fff;
  			border-right: 2px solid transparent;
  		}

  	}
  }
  #news-sections-header, #more-sections-header, #members-sections-header{
  	&:after{
  		padding-left: 4px;
  		font-size: 10px;
  		content: "\e002";
  		font-family: 'mprnews';
  		speak: none;
  		font-style: normal;
  		font-weight: normal;
  		font-variant: normal;
  		text-transform: none;
  		line-height: 1;
  		-webkit-font-smoothing: antialiased;
  	}
  }
  .nav-open{
  	#news-sections-header, #more-sections-header, #members-sections-header{
  		&:after{
  			content: "\e005";
  			font-size: 16px;
  			color: $mpr-link-color-hover;
  		}
  	}
  }


  #nav-top-opener{
  	display: none !important;
  }
  #nav-more a.nav-dropdown-header{ color: $mpr-link-color;}

  #nav-faux-wrapper{
  	display: block;
  	width: 100%;
  	border-top: $mpr-border;
  }

  /*
   Move elements left 25 px to accomodate the paginators
   */
  #nav-top-contribution{
  	right: 12px;
  }
  #nav-weather{
  	right: 75px;
  }
  #nav-search{
  	//margin-right: 25px;
  	.searchbox{
  		height: 52px;
  	}
  }
  #nav-search.search-open{
  	padding-left: 208px;
  	.searchbox{
  		padding-left: 10px;
  	}
  }

}

//----------------------------------
// Headings
//----------------------------------

.hdg {
  font-family: $font-family-heading;
  font-weight: 600;
  color: $color-heading;

  svg {
    fill: $color-heading;
    width: 18px;
    height: 18px;
    vertical-align: -1px;
    margin: -2px 6px -2px 0;
  }
}

.hdg-1 {
  font-size: 24px;
}

.hdg-2 {
  font-size: 20px;
}

.hdg-3 {
  font-size: 15px;
}

.hdg-em {
  color: $color-heading-dark;
}

.hdg-understated {
  font-weight: normal;
}

.hdg-inline {
  display: inline-block;
  margin-right: 10px;
}

.hdg-2_img {
  display: inline-block;
  height: 14px;
  width: auto;
  margin-right: 7px;
}

footer {
  /* variables copied from mprnews.org styles */

  --color-mpr-blue: #4298b5;
  --color-mpr-blue-trans: rgba(66, 152, 181, 0.5);
  --color-mpr-blue-trans-bg: rgba(66, 152, 181, 0.1);
  --color-mpr-green: #2eba80;
  --color-mpr-green-light: #9ee6C9;
  --color-mpr-green-med: #29a370; 
  /* ^ just dark enough for white large text to have enough contrast */
  --color-mpr-green-dark: #259466;
  --color-mpr-green-hover: #30d993;
  --color-mpr-yellow: #f9a909;
  --color-mpr-red: #eb5c40;
  --color-mpr-red-dark: #C74E36;
  --color-mpr-red-hover: #ee5236;
  --color-mpr-pink: #fc6d80;

  --color-bg-gray: #f1f2f2;
  --color-mpr-light-gray: #d3d2d0;
  --color-mpr-dark-gray: #757575;
  --color-mpr-charcoal: #2a2a2a;
  --color-mpr-charcoal-trans: rgb(42, 42, 42, 0.8);
  --color-mpr-button-blue: #327e98;
  --color-mpr-button-blue-dark: #205060;

  --color-black: #000; /* or just use `white` or `#fff` */
  --color-black-trans: rgba(0, 0, 0, 0.2);
  --color-white: #fff; /* or just use `white` or `#fff` */
  --color-white-trans: rgba(255, 255, 255, 0.8);

  --subtle-shadow: 0 .25rem 0.625rem 0 #0000000D;

  /* font sizes scale linearly between 25–75rem */
  /* to recalculate: use clamp.font-size.app and change vw to vmin */

  /* microcopy — captions, related links, sidebar text: 13/15 – 14/16px */
  --font-size-sm: clamp(0.8125rem, 0.7813rem + 0.125vmin, 0.875rem);
  --line-height-sm: clamp(0.9375rem, 0.9063rem + 0.125vmin, 1rem);
  /* body text, user copy h4+: 15/17 – 16/18px */
  --font-size-md: clamp(0.9375rem, 0.9063rem + 0.125vmin, 1rem);
  --line-height-md: clamp(1.0625rem, 1.0313rem + 0.125vmin, 1.125rem);
  /* teaser headlines, user copy h3, buttons: 17/19 – 18/20px */
  --font-size-lg: clamp(1.0625rem, 1.0313rem + 0.125vmin, 1.125rem);
  --line-height-lg: clamp(1.1875rem, 1.1563rem + 0.125vmin, 1.25rem);
  /* category headings, user copy h2, slider headlines: 20/22 – 22/24px */
  --font-size-xl: clamp(1.25rem, 1.1875rem + 0.25vmin, 1.375rem);
  --line-height-xl: clamp(1.375rem, 1.3125rem + 0.25vmin, 1.5rem);
  /* sidebar headings, calls to action: 22/24 – 26/28px */
  --font-size-2x: clamp(1.375rem, 1.25rem + 0.5vmin, 1.625rem);
  --line-height-2x: clamp(1.5rem, 1.375rem + 0.5vmin, 1.75rem);
  /* page names, primary headlines: 24/26 – 34/36px */
  --font-size-3x: clamp(1.5rem, 1.1875rem + 1.25vmin, 2.125rem); 
  --line-height-3x: clamp(1.625rem, 1.3125rem + 1.25vmin, 2.25rem); 

  --gutter: clamp(1.125rem, 0.5rem + 2.5vw, 2.5rem);
}

.footer {
  position: relative;
  z-index: 2;
  color: #fff;
  background-color: var(--color-mpr-charcoal);
  padding: 5rem var(--gutter) 10rem;
  background-image:
    linear-gradient(
      -2.5deg,
      transparent 2rem, var(--color-mpr-red) 2.1rem,
      var(--color-mpr-red) 5rem, transparent 5.1rem
    ),
    linear-gradient(
      183deg, 
      var(--color-mpr-green) 3rem, transparent 3.1rem
    ),
    linear-gradient(
      1deg,
      var(--color-mpr-blue) 2rem, transparent 2.1rem,
      transparent 3rem, var(--color-mpr-yellow) 3.1rem,
      var(--color-mpr-yellow) 6rem, transparent 6.1rem
    );
}

.footer-contents {
  width: 100%;
  max-width: 80rem;
  margin-inline: auto;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.875rem;
  column-gap: var(--gutter);
  grid-template:
    "logo"   auto
    "links"  auto
    "apps"   auto
    "social" auto
    "copyrt" auto
    / 1fr;
}

.footer-logo-donate {
  grid-area: logo;
}

.footer-logo {
  margin-bottom: var(--line-height-md);
}
.footer-logo img {
  height: var(--font-size-3x);
  width: auto;
}

.footer-donate .btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.625rem 0.5rem;
  gap: 0.4rem;
  border: 1px solid var(--color-mpr-button-blue);
  border-radius: .2rem;
  color: var(--color-white);
  background: var(--color-mpr-button-blue);
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  text-wrap: nowrap;
  transition: all 0.2s;
  cursor: pointer;
}

.footer-links {
  grid-area: links;
}

.footer-link a {
  display: block;
  width: 100%;
  padding-block: 0.25rem;
  font-size: var(--font-size-md);
  line-height: var(--line-height-md);
}

.footer a,
.footer .link {
  color: #fff;
  text-decoration: underline;
  -webkit-text-decoration-color: transparent;
          text-decoration-color: transparent;
}
.footer a:hover, .footer a:focus,
.footer .link:hover,
.footer .link:focus {
  color: #fff;
  -webkit-text-decoration-color: #fff;
          text-decoration-color: #fff;
}

.footer svg {
  fill: currentColor;
}

.footer-apps {
  grid-area: apps;
}

.footer h3 {
  font-size: var(--font-size-md);
  line-height: var(--line-height-md);
  font-weight: 600;
}

.footer-apps li {
  margin-top: 0.5rem;
}

.footer-app-link-img {
  height: 2.5rem;
  width: auto;
}

@supports (mix-blend-mode: screen) {
  /* If the browser supports it: 
  /* desaturate the app store links & match the background color */
  .footer-app-link a {
    filter: grayscale(100%);
    background-color: var(--color-mpr-charcoal);
    mix-blend-mode: screen;
  }
  /* but show full-color on focus */
  .footer-app-link a:active,
  .footer-app-link a:focus,
  .footer-app-link a:hover {
    filter: none;
    background-color: transparent;
    mix-blend-mode: normal;
  }
}

.footer-social {
  grid-area: social;
}

.footer-social h3 {
  margin-bottom: 0.5rem;
}

.footer-social-links {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
}

.footer-social-links .icon {
  height: var(--font-size-xl);
  width: var(--font-size-xl);
  transition: all 0.1s;
}
.footer-social-links .icon + span {
  display: none;
}
.footer-social-links .icon.icon-empty {
  display: none;
}
.footer-social-links .icon.icon-empty + span {
  display: flex;
}

.footer-social-links a:active .icon,
.footer-social-links a:focus .icon,
.footer-social-links a:hover .icon {
  color: var(--color-mpr-light-gray);
  transform: scale(1.05);
}

.footer-copyright {
  grid-area: copyrt;
  font-size: var(--font-size-sm);
  line-height: var(--line-height-sm);
}

@media screen and (min-width: 44em) {
  .footer-contents {
    grid-template:
      "logo   links " auto
      "apps   links " auto
      "social links " 1fr
      "copyrt copyrt" auto
      / 1fr 1fr;
  }
}

@media screen and (min-width: 66em) {
  .footer-contents {
    grid-template:
      "logo   links  links " auto
      "apps   links  links " auto
      "social links  links " 1fr
      "copyrt copyrt copyrt" auto
      / 1fr 1fr 1fr;
  }

  .footer-link-list {
    columns: 2;
    column-gap: var(--gutter);
  }
  .footer-link {
    break-inside: avoid;
  }
}

@media screen and (min-width: 72em) {
  .footer-contents {
    grid-template:
      "logo   links  links  apps  " auto
      "logo   links  links  social" 1fr
      "copyrt copyrt copyrt copyrt" auto
      / 1fr 1fr 1fr 1fr;
  }
}

@media screen and (min-width: 88em) {
  .footer-contents {
    grid-template:
      "logo   links  links  apps  social" 1fr
      "copyrt links  links  apps  social" auto
      / auto 1fr 1fr 1fr auto;
  }
}


//----------------------------------
// PresidentialPrimary
//----------------------------------

.PresidentialPrimary {
  $border-color: $color-mid;
  font-family: $font-family-heading;

  .row {
    border-top: 1px solid $color-mid;
    display: flex;
    flex-direction: row;
    align-content: center;

    @include media($media-lg) {
      align-items: center;
    }

    .row {
      border: 0;
    }
  }


  .row-candidate {
    padding: 10px 0;

    &:first-child {
      border-top: 1px solid $color-light;
    }
  }

  .row-wrap {
    flex-wrap: wrap;
  }

  .column-state {
    width: 100%;
    min-width: 135px;
    align-self: start;

    @include media(64em) {
      width: 10%;
    }
  }

  .column-candidate {
    width: 100%;
    margin-left: 12px;

    @include media(64em) {
      width: 77%;
    }

    @include media($media-xl) {
      width: 70%;
    }

    @include media(80em) {
      width: 78%;
    }
  }

  .cell {
    width: 178px;
    padding: 0 0 0 2px;

    &.cell-candidate {
      width: 185px;
    }
  }

  .cell_row {
    display: flex;
    flex-direction: row;
  }

  .cell-national,
  .cell-state {
    width: 100%;
    display: flex;
    align-items: center;
    letter-spacing: -.25px;

    @include media(64em) {
      width: 90px;
    }
  }

  .cell-candidate {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 0;
  }

  .cell_delegates {
    font-weight: 600;
  }

  .cell_icon {
    height: 2.25rem;
    width: 2.25rem;
    border: 2px solid $color-mpr-primary;
    border-radius: 2rem;
    overflow: hidden;
    margin: 0 10px 0 0;

    img {
      display: block;
      // width: 100%;
      // height: auto;
      width: 32px;
      height: 32px;
    }
  }

  // add party-specific background colors
  [data-party='Dem'] .cell_icon {
    background-color: mix($color-white, $color-dem, 85%);
  }

  [data-party='GOP'] .cell_icon {
    background-color: mix($color-white, $color-gop, 85%);
  }

  .cell_name,
  .cell_vote {
    // Keep lines together at the awkward sizes
    // that are still too big for vertical layout
    white-space: nowrap;
    display: flex;
  }

  .cell_vote-em {
    font-style: italic;
  }

  .cell_reporting {
    font-style: italic;
    font-size: .775em;
    position: relative;
    color: $color-mpr-primary;
    align-self: center;

    @include media(64em) {
      top: -7px;
      margin-left: 60px;
    }
  }

  .cell_name {
    letter-spacing: -.25px;

    &.roquedelafuente,
    .totalwrite-ins {
      letter-spacing: -1px;
    }

    &.elizabethwarren {
      letter-spacing: -.65px;
    }
  }


  .cell_votePercent {
    display: inline-block;
    margin-right: 3px;
    background: $color-white;
    padding: 0 1px;
  }

  .cell_voteCount {
    display: flex;
    align-items: center;
    padding: 0 .25em;
    background: $color-election-dark;
    color: $color-white;

    &.winner {
      background-color: darken($color-green, 10%);
      color: $color-white;
      font-weight: 600;

      .cell_voteNumber {
        padding-left: 3px;
      }

      .icon-checkmark {
        fill: $color-white;
      }

      svg {
        height: 12px;
        width: 12px;
        position: relative;
        top: 1px;
      }
    }
  }

  .results-legend {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    color: $color-mpr-primary;
  }

  .results_source {
    text-transform: uppercase;
    font-size: .875em;
    padding: 0 4px 10px;
  }

  .results-header,
  .results-key {
    padding: 10px 4px;
    font-weight: 600;
    color: $color-dark;
  }

  .results-minnesota-first + .section {
    // Kind of a workaround to add Minnesota to the top
    // of the state list and style it differently,
    // but still look like part of the same list
    margin-bottom: 10px;

    .row {
      background-color: $color-light;
      box-shadow: 0 2px 4px $color-black-30;

      .row {
        box-shadow: none;
      }
    }
  }
}


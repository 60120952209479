//---------------------------------------
// Layout Grid
//---------------------------------------

$col-gutter: 20px;
$col-sidebar-width: 300px;

.grid {
  @include clearfix();
}

// .col-main and .col-main_content allow the left column to come first in markup
.grid_col-main {
  margin-bottom: 3rem;

  @include media($media-xl) {
    margin-bottom: 0;
    margin-right: -$col-sidebar-width;
    float: left;
    width: 100%;
  }
}

.grid_col-sidebar {
  position: relative;

  @include media($media-xl) {
    width: $col-sidebar-width;
    float: left;
  }
}

// For main single column layout
.grid_col-narrow {
  width: 100%;
  max-width: 920px;
  margin: 0 auto;

  @include media(32em) {
    padding-left: 5%;
    padding-right: 5%;
  }

  @include media($media-xl) {
    padding-left: 2%;
    padding-right: 2%;
  }
}

.grid_col-narrow select {
  max-width: 100%;
}

.grid_col-half {
  margin-bottom: 40px;

  @include media($media-md) {
    width: 50%;
    padding: 0 ($col-gutter / 2);
    float: left;
  }
}

.grid_col-oneThird {
  margin-bottom: 40px;

  @include media(32em) {
    width: 50%;
    padding: 0 ($col-gutter / 2);
    float: left;
  }

  @include media($media-md) {
    width: 33%;
  }
}

.grid_col-twoThirds {
  margin-bottom: 40px;

  @include media(32em) {
    width: 50%;
    padding: 0 ($col-gutter / 2);
    float: left;
  }

  @include media($media-md) {
    width: 67%;
  }
}

// sass-lint:disable no-ids

#electionTable {
  font-family: $font-sans;
  border: 1px solid $color-mid;
  margin-bottom: 1em;
  font-size: .9rem;
  width: 100%;

  th {
    min-width: 60px;
    max-width: 60px;
    white-space: nowrap;
    padding: .5em;
  }

  td {
    padding: 2px;
    text-align: center;
    border-right: 1px solid $color-light;
    font-weight: 600;
    scroll-snap-align: start;
  }

  tr {
    border-bottom: 1px solid $color-light;
  }

  .firstColumn {
    font-family: $font-family-heading;
    background: transparent;
    border-right: none;
    background-image: 
      linear-gradient(
        to right, 
        $color-white 2.5rem, 
        transparent 100%
      );
  }

  .firstColumn-sticky {
    position: sticky;
    left: 0;
  }

  //.lastRow {
  //border-bottom: 1px solid $color-mid;
  //}

  .firstRow {
    position: sticky;
    text-align: center;
    flex-direction: column;
    border-bottom: 1px solid $color-mid;
    background-color: $color-light;

    .firstColumn {
      flex-direction: column;
      border-right-color: $color-light;
      background-color: transparent;
      background-image: 
        linear-gradient(
          to right, 
          $color-light 2rem, 
          transparent 100%
        );
    }

    th {
      border-right: 1px solid $color-mid;
    }
  }

  .firstRow_votes {
    font-size: .8rem;
    color: $color-mid;
  }

  .firstRow_postal {
    font-family: $font-family-heading;
  }

  .cellIcon {
    height: 2.5rem;
    width: 2.5rem;
    padding: 2px;

    img {
      display: block;
      width: 2.25rem;
      height: 2.25rem;
      border: 2px solid $color-heading-dark;
      border-radius: 2rem;
      overflow: hidden;
    }
  }

  .controls {
    display: flex;

    button {
      width: 100%;
      border: 0;
      border-radius: 3px;
      background-color: $color-mpr-primary;
      color: $color-white;
      font-weight: 600;
      font-size: 1.4rem;
      margin: 2px;

      &:hover {
        background-color: $color-blue-faded;
      }

      &:disabled {
        opacity: .4;
      }
    }
  }

  .scroll {
    overflow: auto;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
  }

  .candidateLabel {
    border-right: 1px solid $color-mid;
    min-width: 140px;
    font-weight: 600;
    text-align: left;
    font-variation-settings: "wdth" 85;
  }

  .showMore {
    font-family: $font-family-heading;
    font-weight: 600;
    margin: 5px 0;
    border: 0;
    background-color: transparent;
    width: 100%;
    align-self: center;
  }

  .winner {
    color: $color-white;
    background-color: $color-green;

    &::before {
      content: '✔ ';
    }
  }
}


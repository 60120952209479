//----------------------------------
// Utility Classes
//----------------------------------

.invisible {
  @include invisible();
}

.hidden {
  display: none;
}

.mobile {
  @include media($media-md) {
    display: none;
  }
}

.tabletDown {
  @include media($media-lg) {
    display: none;
  }
}

.tabletUp {
  @include media-max($media-md) {
    display: none;
  }
}

.desktop {
  @include media-max($media-lg) {
    display: none;
  }
}

.mobileCentered {
  text-align: center;

  @include media($media-md) {
    text-align: left;
  }
}

//----------------------------------
// Race
//----------------------------------

.race-ev {
  margin-bottom: 20px;
}

.race_header {
  margin: 8px 0;
}

.race_graph {
  position: relative;
}

.race_graph-bop {
  &::before {
    content: '';
    display: block;
    height: 110%;
    position: absolute;
    top: -5%;
    left: 50%;
    margin-left: -1.5px;
    border-radius: 100px;
    z-index: $z-graph-overlay;
    border: 1px solid $color-election-dark;
    background: $color-white;
    width: 3px;
  }

  &::after {
    content: normal;
  }
}

.race_graph_legend {
  position: absolute;
  right: 0;
  top: -22px;
}

.race_body-pad {
  margin-bottom: 10px;
}

.race_graph_overlay,
.race_graph_val {
  font-family: $font-family-heading;
  font-weight: 600;
}

.race_graph_overlay {
  font-size: 12px;
  color: $color-election-dark;
  margin-bottom: 2px;
}

.race_graph_val {
  position: absolute;
  color: $color-white;
  bottom: 0;
  font-size: 16px;
  z-index: $z-graph-overlay;
  padding: 0 0.2rem 0.2rem 0.2rem;
}

.race_graph_val-left {
  left: 2px;
}

.race_graph_val-right {
  right: 2px;
}

.race_candidates {
  @include clearfix();
  position: relative;
}

.race_party_pickup {
  padding: 0 .1em;
  font-weight: 600;
}

.bopChart_title {
  margin-bottom: 10px;
}

.pull-right {
  float: right;
}

.chart_label {
  margin-bottom: 5px;
}

.bopChart_container {
  display: grid;
}

.bopChart_candidates {
  position: relative;
}

.bopChart_legend {
  position: relative;
  clear: both;
  padding-top: 8px;
}

.hidden {
  display: none;
}

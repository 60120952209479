//----------------------------------
// Ad Slots
//----------------------------------

.ad {
  text-align: center;
}

.ad_slot {
  display: inline-block;
  width: 300px;
  height: 250px;
  margin-right: 20px;
  margin-bottom: 20px;
  background: $color-light;

  @include media($media-xl) {
    margin-right: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.ad_top {
  text-align: center;
  margin-bottom: 1em;

  @include media($media-md) {
    text-align: left;
  }
}

//----------------------------------
// Site Header
//----------------------------------

.header {
  padding: 12px;
  text-align: center;

  @include media($media-lg) {
    width: 200px;
    padding: 0;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: $z-header-logo;
  }
}

.header_logo {
  display: inline-block;

  @include media($media-lg) {
    display: none;
  }
}

.header_logo_img {
  display: block;
  width: 120px;
  height: auto;
}

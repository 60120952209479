//----------------------------------
// Loading Spinner
//----------------------------------

.loading {
  text-align: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading_text {
  display: block;
  margin-bottom: 10px;
  font-style: italic;
  color: $color-election-dark;
}

.spinner {
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  border: 6px solid $color-light;
  border-top-color: $color-election-blue;
  animation: spin 1.5s infinite linear;
}

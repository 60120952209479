//----------------------------------
// Tile
//----------------------------------

.tile {
  display: inline-block;
  padding: 5px;
  background-color: $color-light;
  color: $color-election-dark;

  &:hover {
    background-color: $color-election-blue;
    color: $color-white;
  }
}

.tile_img {
  width: 100%;
  margin-bottom: 5px;
}

.tile_caption {
  font-family: $font-family-heading;
  font-weight: 600;
  font-size: 18px;
}

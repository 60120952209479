//----------------------------------
// Buttons
//----------------------------------

.button {
  display: inline-block;
  padding: 2px 6px;
  border: 0;
  border-right: 3px solid $color-election-dark;
  font-family: $font-family-heading;
  font-weight: 600;
  font-size: $font-size-default;
  color: $color-election-dark;
  fill: $color-election-dark;
  background: $color-light;
  vertical-align: top;
  cursor: pointer;

  &:hover {
    color: $color-election-blue;
    fill: $color-election-blue;
    border-right-color: $color-election-blue;
  }
}

.button-marginRight {
  margin-right: 12px;
}

.button-marginTop {
  margin-top: 6px;
}

.button-lg {
  padding: 6px 12px;
  font-size: 16px;
}

.button_icon {
  height: 16px;
  width: 16px;
  margin-left: 8px;
  vertical-align: -2px;

  &.left {
    margin-left: 0;
    margin-right: 4px;
    vertical-align: -3px;
  }
}

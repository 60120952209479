//----------------------------------
// Candidate
//----------------------------------

.candidate {
  @include clearfix();
  display: none;
  width: 50%;
  float: right;

  &:first-child {
    display: block;
    float: left;
  }

  &:nth-child(2) {
    display: block;

    .candidate_pct_win {
      &::before {
        position: absolute;
        top: 2px;
        left: -22px;
        font-family: $font-family-heading;
        font-weight: 600;
        font-size: 12px;
        text-transform: uppercase;
        color: $color-green;
      }

      &.ap::before {
        content: 'AP';
      }

      &.mn_oss::before {
        left: -30px;
        content: 'Final';
      }
    }
  }

  &:not(:first-child):not(:nth-child(2)) {
    &.active {
      display: block;
      width: auto;
      padding: 0 10px;
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      background: rgba($color-white, 1);
    }
  }
}

.candidate_caveat {
  font-size: 10px;
}

.candidate_tag {
  margin-left: 6px;
  float: right;
  font-family: $font-family-heading;
  font-weight: 600;
  font-size: 10px;
  line-height: 1;
  text-transform: uppercase;
  color: $color-white;
  background-color: $color-wi;

  &.dem { background-color: $color-dem; }

  &.gop { background-color: $color-gop; }

  &.ind { background-color: $color-ind; }

  &.grn { background-color: $color-grn; }

  &.lib { background-color: $color-lib; }

  &.lmn { background-color: $color-lmn; }

  &.grp { background-color: $color-grp; }

  &.cst { background-color: $color-cst; }

  &.adp { background-color: $color-adp; }

  &.swp { background-color: $color-swp; }

  &.yes { background-color: $color-yes; }

  &.no { background-color: $color-no; }

  &.oth { background-color: $color-oth; }

  &.wi { background-color: $color-wi; }
}

.candidate_tag_party {
  display: block;
  padding: 3px 6px;
  text-align: center;
}

.candidate_tag_img {
  height: 40px;
  width: 40px;
  background: $color-white-85;

  + .candidate_tag_party {
    padding: 1px;
  }
}

.candidate_stats {
  text-align: right;
  overflow: hidden;
}

.candidate:first-child {
  .candidate_votes_inline {
    float: left;
    margin-right: 6px;
    margin-left: 0;
  }

  .candidate_tag {
    float: left;
    margin-left: 0;
    margin-right: 6px;
  }

  .candidate_stats {
    text-align: left;

    .candidate_pct_win {
      margin-right: 20px;
    }
  }
}

.candidate-compact {
  .candidate_stats > * {
    float: right;
    margin: 0 4px;
  }

  &:first-child {
    .candidate_stats > * {
      float: left;
      margin: 0 4px;
    }
  }
}

.candidate_pct {
  display: inline-block;
  padding: 0;
  font-family: $font-family-heading;
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
}

.candidate_pct_win {
  padding-left: 2px;
  padding-right: 2px;
  position: relative;
  background-color: $color-green;
  color: $color-white;

  &::after {
    position: absolute;
    top: 2px;
    right: -16px;
    font-family: $font-family-heading;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    color: $color-green;
  }

  &.ap::after {
    content: 'AP';
  }

  &.mn_oss::after {
    content: 'Final';
    right: -30px;
  }

  svg {
    display: inline-block;
    height: 11px;
    width: 14.5px;
    vertical-align: -1px;
    fill: $color-white;
  }
}

.candidate_votes {
  font-size: 13px;
}

.candidate_votes_em {
  font-weight: 600;
}

.candidate_votes_inline {
  float: right;
  margin-left: 6px;
}

.candidate-compact:first-child,
.candidate-compact:not(:first-child) {
  .candidate_votes_inline {
    margin: 2px 6px 0;
  }
}

.candidate_win {
  font-family: $font-family-heading;
  font-weight: 600;
  font-size: 12px;
  color: $color-green;

  svg {
    height: 14px;
    width: 14px;
    fill: $color-green;
    vertical-align: -2px;
  }
}

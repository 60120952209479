//----------------------------------
// Nav
//----------------------------------

.nav {
  width: $nav-full-width;
  padding-top: 40px;
  border-right: 3px solid $color-light;
  position: absolute;
  left: -204px;
  top: 0;
  bottom: 0;
  z-index: $z-nav;
  background-color: $color-white;
  font-family: $font-family-heading;
  font-weight: 600;
  font-size: 15px;
  font-variation-settings: "wdth" 85;
  transition: left .2s;

  @include media($media-md) {
    left: $nav-mini-width - $nav-full-width;
  }

  @include media($media-lg) {
    left: 0;
    padding-top: 12px;
  }

  &.active {
    left: 0;
  }
}

.nav_button {
  padding: 8px 10px;
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  font-family: $font-family-heading;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: $color-election-dark;
  background: $color-light;
  z-index: $z-nav-button;

  svg {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 4px;
    fill: $color-election-dark;
    vertical-align: -3px;
  }

  @include media($media-md) {
    padding: 10px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: $z-nav-button;
    border-right: 0;
    background-color: transparent;

    &::after {
      content: none;
    }

    svg {
      display: block;
      margin-right: 0;
    }
  }

  @include media($media-lg) {
    display: none;
  }
}

.nav_button_label {
  vertical-align: 1px;

  @include media($media-md) {
    display: none;
  }
}

.nav_logo {
  display: none;

  @include media($media-lg) {
    display: block;
    margin-bottom: 12px;
    position: relative;
    text-align: center;
    z-index: $z-header-logo;
    font-size: 0; // Get rid of empty space below img caused by inline-block
  }
}

.header_logo_img {
  display: inline-block;
  width: 120px;
  height: auto;
}

.nav_close {
  position: absolute;
  border: 0;
  right: -3px;
  top: 0;
  height: 40px;
  width: 40px;
  background: $color-light;

  svg {
    width: 20px;
    height: 20px;
    fill: $color-election-dark;
  }

  @include media($media-md) {
    display: none;
  }
}

.nav_link {
  display: block;
  margin-right: -3px;
  padding: 9px 4px 9px 0;
  border-right: 3px solid transparent;
  position: relative;
  line-height: 1;
  color: $color-election-dark;
  text-align: right;

  svg {
    width: 20px;
    height: 20px;
    margin-left: 8px;
    vertical-align: -4px;
    fill: $color-election-dark;
    stroke: $color-white;
  }

  &:hover {
    color: $color-election-dark;
    background-color: $color-light;
    border-right-color: $color-election-dark;

    svg {
      fill: $color-election-dark;
    }
  }

  &.active {
    color: $color-white;
    background-color: $color-election-dark;
    border-right-color: $color-mpr-primary;

    &::after {
      content: '';
      display: block;
      height: 14px;
      width: 6px;
      margin-top: -7px;
      position: absolute;
      right: -7px;
      top: 50%;
      background: url('../../assets/svg/icon-pointer-right.svg');
    }

    svg {
      fill: $color-white;
      stroke: $color-election-dark;
    }
  }
}

//----------------------------------
// State SVGs
//----------------------------------

.state {
  display: flex;

  @include media(64em) {
    display: block;
  }

  &.state-wrapper {
    width: 100%;
    border-bottom: 1px solid $color-light;
    margin-bottom: 10px;

    @include media(64em) {
      width: 135px;
      border: 0;
    }
  }
}

.state_flex {
  display: flex;
}

.state_margin {
  margin: 0 10px;
}

//--------------------------------------------------
// Mixins
//
// Reusable CSS to be used inside selectors
//--------------------------------------------------

// Media query mixin. Will flatten media queries when the
// $no-media-queries variable is set to true.
//
// For example, the following code with media queries:
//
// div { color: red; }
// media (min-width: 24em) {
//   div { color: blue; }
// }
//
// will become:
//
// div { color: red; }
// div { color: blue; }
//
// This means that legacy browsers will properly view
// overridden tablet and desktop values instead of
// displaying a mobile site
// Source: http://seesparkbox.com/foundry/structuring_and_serving_styles_for_older_browsers
$no-media-queries: false !default;
$max-legacy-width: 60em;

@mixin media($query) {
  @if $no-media-queries {
    @if $query < $max-legacy-width {
      @content;
    }
  } @else {
    @media ('min-width: ' + $query) {
      @content;
    }
  }
}

// Max-width media queries
// Will not output for legacy browsers
@mixin media-max($query) {
  @if $no-media-queries == false {
    @media ('max-width: ' + ($query - .001em)) {
      @content;
    }
  }
}

// Clearfix, self-clearing floats for containers
// Nicholas Gallagher's micro clearfix: http://nicolasgallagher.com/micro-clearfix-hack/
@mixin clearfix() {
  &::before,
  &::after {
    content: ' ';
    display: table;
  }

  &::after {
    clear: both;
  }
}

// ACCESSIBILITY
@mixin invisible() {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
}

// Retina background-image support with non-retina fall back
@mixin retina-image($file-1x, $file-2x, $width-1x, $height-1x) {
  background-image: url('#{$file-1x}');

  @media only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
      background-image: url('#{$file-2x}');
      background-size: $width-1x $height-1x;
    }
}

// Pointer, used as the chevron underneath tabs
@mixin pointer() {
  content: '';
  display: block;
  width: 21px;
  height: 16px;
  margin-left: -11.5px;
  position: absolute;
  bottom: -12px;
  left: 50%;
  background-image: url('../images/pointer.svg');
  background-size: 21px auto;
  background-repeat: no-repeat;
}

// Blocks mixin
@mixin blocks-layout($gutter, $sizes, $query: '', $label: '') {
  @if $query != '' {
    @include media($query) {
      @each $size in $sizes {
        $_width-without-gutters: 100% - ($gutter * $size);
        $_block-width: $_width-without-gutters / $size;

        .blocks-#{$label}-#{$size} {
          > * {
            width: $_block-width;
          }
        }
      }
    }
  } @else {
    @each $size in $sizes {
      $_width-without-gutters: 100% - ($gutter * $size);
      $_block-width: $_width-without-gutters / $size;

      .blocks-#{$size} {
        > * {
          width: $_block-width;
        }
      }
    }
  }
}


// Reset all default button and link styles
@mixin reset-button() {
  display: inline-block;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  appearance: none;
  text-align: center;
  text-decoration: none;
  line-height: 1;
  background: transparent;
  cursor: pointer;
}

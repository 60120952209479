//----------------------------------
// Section
//----------------------------------

.section {
  margin-bottom: 40px;
}

.section-md {
  margin-bottom: 20px;
}

.section-sm {
  margin-bottom: 5px;
}

.section_header {
  margin-bottom: 8px;
}

.section_subheader {
  margin-bottom: 16px;
}

.section.presidentialPrimaryDelegates {
  padding: 12px 12px 12px 0;

  @include media($media-lg) {
    width: 650px;
    height: auto;
  }

  @include media(1120px) {
    width: 575px;
    height: auto;
  }

  @include media(1440px) {
    width: 775px;
    height: auto;
  }
}

.section-borderBottom {
  border-bottom: 1px solid $color-mid;
}

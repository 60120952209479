//----------------------------------
// Legends for graphs and maps
//----------------------------------

.legend {
  @include clearfix();
  font-family: $font-family-heading;
  font-weight: 600;
  font-size: 10px;
  line-height: 1.4;
  color: $color-dark;
}

.legend_won,
.legend_trending {
  float: left;
  margin-left: 10px;

  &::before,
  &::after {
    content: ' ';
    display: block;
    float: left;
    height: 12px;
    width: 12px;
    margin-right: 3px;
  }

  &::before {
    background-color: $color-dem;
  }

  &::after {
    background-color: $color-gop;
  }

  .independent {
    &::after {
      content: ' ';
      display: block;
      float: left;
      height: 12px;
      width: 12px;
      background-color: $color-ind;
      margin-right: 3px;
    }
  }
}

.legend_trending {
  &::before,
  &::after {
    opacity: .3;
  }

  .independent {
    &::after {
      opacity: .3;
    }
  }
}

.legend_noResults {
  float: left;
  margin-left: 10px;

  &::before {
    content: ' ';
    display: block;
    margin-right: 3px;
    float: left;
    height: 12px;
    width: 12px;
    background-color: $color-no-results;
  }
}

//----------------------------------
// Basic page layout
//----------------------------------

.siteContainer {
  position: relative;
}

.container {
  padding: 0 10px;
  box-sizing: content-box;

  @include media($media-lg) {
    margin: 0 auto;
    padding: 0 25px;
    max-width: 1080px;
  }
}

.main {
  padding: 10px 0 40px;
  margin: 0 $gutter-width;
  min-height: 580px;
  position: relative;

  @include media-max(22.5em) {
    margin-left: 10px;
    margin-right: 10px;
  }

  @include media($media-md) {
    margin-left: $nav-mini-width + $gutter-width;
  }

  @include media($media-lg) {
    margin-left: $nav-full-width + $gutter-width;
  }
}

.main_header {
  margin-bottom: 62px;

  @include media($media-md) {
    margin-bottom: 20px;
  }
}

.main_header-compact {
  margin-bottom: 10px;
}

.main_back {
  display: inline-block;
  margin-bottom: 20px;
  font-family: $font-family-heading;
  font-weight: 600;
  font-size: 16px;
  color: $color-election-dark;
}

.result_iframe {
  width: 100%;
  max-width: 920px;
  padding: 0;
  margin: 0 auto;
  overflow-x: hidden;

  .grid_col-narrow {
    margin: 0;
    padding: 0;
  }
}
